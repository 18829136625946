import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = {
  key: 0,
  class: "formContainer"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { colspan: "10" }
const _hoisted_5 = { class: "fs_12 fw-bold red h-100" }
const _hoisted_6 = { class: "h-100 d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        type: 'h1',
        localizedKey: "Licenses history",
        text: "Licenses history",
        class: "mb-0"
      })
    ]),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_op_table, {
            class: "table table-striped",
            items: _ctx.items
          }, {
            default: _withCtx((opData) => [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Services",
                      text: "Services"
                    })
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Concurrent Accesses",
                      text: "Concurrent Accesses"
                    })
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Purchased on",
                      text: "Purchased on"
                    })
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Months",
                      text: "Months"
                    })
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Price",
                      text: "Price"
                    })
                  ]),
                  _createElementVNode("td", null, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Discount %",
                      text: "Discount %"
                    })
                  ])
                ])
              ]),
              _createElementVNode("tbody", null, [
                (opData.data.length == 0)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
                      _createElementVNode("td", _hoisted_4, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "No Elements Found",
                          text: "No Elements Found"
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(opData.data, (item, i) => {
                  return (_openBlock(), _createElementBlock("tr", { key: i }, [
                    _createElementVNode("td", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.serviceSoldConfiguration.garmentsCalculation, (garm, k) => {
                          return (_openBlock(), _createElementBlock("span", {
                            class: "me-1 ms-1 mb-1",
                            key: k
                          }, _toDisplayString(garm.text), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("td", null, _toDisplayString(item.serviceSoldConfiguration.userLicenseCount), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.$filters.date(item.serviceSoldConfiguration.modifiedOn, "DD/MM/yyyy HH:mm", false)), 1),
                    _createElementVNode("td", null, _toDisplayString(item.serviceSoldConfiguration.monthDuration), 1),
                    _createElementVNode("td", null, _toDisplayString(_ctx.$filters.currency(item.serviceSoldConfiguration.price)) + " CHF", 1),
                    _createElementVNode("td", null, _toDisplayString(item.serviceSoldConfiguration.percentageDiscount ? item.serviceSoldConfiguration.percentageDiscount + "%" : '-'), 1)
                  ]))
                }), 128))
              ])
            ]),
            _: 1
          }, 8, ["items"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}