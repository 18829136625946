
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, GarmentClient, ServiceSoldConfigurationClient, StripeClient } from '@/services/Services';
import EditModal from '../modals/editModal.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import * as OM from '@/Model';


@Options({
    components: {}
})
export default class ServicesHistory extends Vue {

    items: OM.MyServiceListVM[] = [];
    garments: OM.TextIdentifier[] = [];
    loaded: boolean = false;

    created() {
        this.init();
    }

    init() {
        Promise.all([
            StripeClient.getMyServiceList()
        ]).then(xs =>{
            this.items = xs[0];
            this.loaded = true;
        })
    }

}
